import React, {
    useRef,
    useEffect,
    useState
} from "react";

import logo from "../assets/images/logo/dodeal-white.png";
import logoD from "../assets/images/parts/Plain/1x/d-white.png";
import logoO from "../assets/images/parts/Plain/1x/o-green.png";
import pattern1 from "../assets/images/parts/pattern-green.png";
import pattern2 from "../assets/images/parts/pattern-white.png";

import {
    PiArrowDownLight
} from "react-icons/pi";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Home() {
    const marker = false;

    const brandLogoRef = useRef(null);
    const oLogoRef = useRef(null);
    const mottoRef = useRef(null);
    const logoRef = useRef(null);

    const leftCurtainRef = useRef(null);
    const rightCurtainRef = useRef(null);
    const scrollToRevealRef = useRef(null);
    const contentAboveRef = useRef(null);
    const contentBelowRef = useRef(null);

    const patternSectionRef = useRef(null);
    const pattern2Ref = useRef(null);

    useEffect(() => {
        if (!leftCurtainRef.current || !rightCurtainRef.current) {
            console.error("Missing references for curtains.");
            return;
        }

        // INITIAL STATES 
        gsap.set(leftCurtainRef.current, { x: "0%" });
        gsap.set(rightCurtainRef.current, { x: "0%" });
        gsap.set(scrollToRevealRef.current, { opacity: 1 });
        gsap.set(contentAboveRef.current, { opacity: 1 });

        gsap.set(logoRef.current, {
            opacity: 0,
            top: "0%",
            left: "50%",
            transform: "translate(-50%, 20px)"
        });
        gsap.set(brandLogoRef.current, {
            opacity: 1,
            scale: 1,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        });
        gsap.set(oLogoRef.current, {
            opacity: 1,
            scale: 1,
            top: "50%",
            left: "50%",
            transform: "translate(30%, -150%)",
        });
        gsap.set(mottoRef.current, {
            opacity: 0,
            scale: 1,
            transform: "translate(20%, 50%)",
        });

        // SCROLL-TRIGGERED ANIMATION 
        // (#) CURTAIN | 10-90 
        const curtainTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: contentBelowRef.current,
                start: "10% top",
                end: "90% bottom",
                scrub: 1,
                markers: marker,
            },
        });
        curtainTimeline.to(leftCurtainRef.current, { x: "-90%", ease: "linear.inOut" });
        curtainTimeline.to(rightCurtainRef.current, { x: "90%", ease: "linear.inOut" }, "<");

        // (1) HIDE SCROLL TO REVEAL | DECREASE OPACITY OF DODEAL | 10-30
        const firstTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: contentBelowRef.current,
                start: "10% top",
                end: "30% bottom",
                scrub: 1,
                markers: marker,
            },
        });
        firstTimeline.to(contentAboveRef.current, { opacity: 0.6, ease: "linear.inOut" });
        firstTimeline.to(scrollToRevealRef.current, { opacity: 0, ease: "linear.inOut" }, "<");

        // (2) OPACITY OF DODEAL | SIDE THE LOGO | 30-80
        const secondTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: contentBelowRef.current,
                start: "30% top",
                end: "80% bottom",
                scrub: 1,
                markers: marker,
            },
        });
        secondTimeline.to(contentAboveRef.current, { opacity: 0, ease: "linear.inOut" });
        secondTimeline.to(brandLogoRef.current, {
            opacity: 1,
            scale: 1,
            transform: "translate(-130%, -50%)",
            ease: "linear.inOut"
        }, "<");
        secondTimeline.to(oLogoRef.current, {
            opacity: 1,
            scale: 1,
            transform: "translate(-180%, -150%)",
            ease: "linear.inOut"
        }, "<");

        // (3) OPACITY FOR MOTTO | 40-100
        const thirdTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: contentBelowRef.current,
                start: "40% top",
                end: "100% bottom",
                scrub: 1,
                markers: marker,
            },
        });
        thirdTimeline.fromTo(mottoRef.current,
            {
                opacity: 0,
                scale: 1,
            },
            {
                opacity: 1,
                scale: 2,
                ease: "linear.inOut"
            }
        );
        thirdTimeline.to(leftCurtainRef.current, { display: "none", ease: "linear.inOut" }, "<");
        thirdTimeline.to(rightCurtainRef.current, { display: "none", ease: "linear.inOut" }, "<");

        // (4) AFTER PATTERN, DECREASE OPACITY OF DODEAL LOGO 
        const fourthTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: patternSectionRef.current,
                start: "50% top",
                end: "bottom bottom",
                scrub: 1,
                markers: marker,
            },
        });
        fourthTimeline.to(brandLogoRef.current, {
            opacity: 0,
            scale: 1,
            transform: "translate(-130%, -50%)",
            ease: "linear.inOut"
        });
        fourthTimeline.to(mottoRef.current, {
            opacity: 0,
            scale: 1,
            ease: "linear.inOut"
        }, "<");
        fourthTimeline.to(oLogoRef.current, {
            opacity: 1,
            scale: 2,
            transform: "translate(-50%, -50%)",
            ease: "linear.inOut"
        }, "<");
        fourthTimeline.to(logoRef.current, {
            opacity: 1,
            ease: "linear.inOut"
        }, "<");

        // CLEAN UP SCROLL TRIGGER ON COMPONENT UNMOUNT 
        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);

    return (
        <div className="relative w-[100vw] h-full">
            {/* HEADER LOGO */}
            <div
                ref={logoRef}
                className="fixed"
            >
                <img src={logo}
                    alt="DoDeal"
                    className="w-[70px]"
                />
            </div>
            {/* O OVERALL */}
            <div
                ref={oLogoRef}
                className="fixed"
                style={{
                    zIndex: 10,
                }}
            >
                <img
                    src={logoO}
                    alt="DoDeal"
                    className="h-[90px]"
                />
            </div>
            {/* D HERO */}
            <div
                ref={brandLogoRef}
                className="fixed"
                style={{
                    zIndex: 9,
                }}
            >
                <img
                    src={logoD}
                    alt="DoDeal"
                    className="h-[300px] object-contain"
                    style={{
                        zIndex: 90,
                    }}
                />
            </div>
            {/* MOTTO */}
            <div
                ref={mottoRef}
                className="fixed uppercase text-2xl grad-anim font-bold"
                style={{
                    top: "50%",
                    left: "50%",
                    zIndex: 9,
                }}
            >
                Your success
                <br />
                Our deal
            </div>
            {/* </div> */}
            {/* CONTENT ABOVE CURTAIN */}
            <div
                ref={contentAboveRef}
                className="content-above fixed top-0 left-0 w-[100vw] h-[100vh]"
                style={{
                    zIndex: 100,
                }}
            >
                <div className="relative w-full h-full flex flex-col gap-5 items-center justify-between py-5">
                    <div className="p-5"></div>
                    <div className="flex flex-col gap-4 justify-center items-center">
                        <div className="hero-brand grad-anim">
                            DODEAL
                        </div>
                        <div
                            ref={scrollToRevealRef}
                            className="uppercase text-xs grad-anim"
                        >
                            Scroll to reveal
                        </div>
                    </div>
                    <div className="p-5">
                        <div className="p-2 border border-[var(--gold-3)] rounded-full">
                            <PiArrowDownLight className="grad-anim" size={16} />
                        </div>
                    </div>
                </div>
            </div>
            {/* CURTAINS */}
            <div
                className="fixed w-[100vw] h-[100vh] overflow-hidden"
                style={{
                    zIndex: 99,
                }}
            >
                {/* CURTAIN ANIMATION */}
                <div
                    className="curtainBody absolute top-0 left-0 w-full h-full"
                    style={{
                        zIndex: 99,
                    }}
                >
                    {/* LEFT CURTAIN */}
                    <div
                        ref={leftCurtainRef}
                        className="curtainContainer left-0"
                        style={{
                            transform: "translateX(0%)",
                            zIndex: 99,
                        }}
                    >
                        {Array.from({ length: 12 }).map((_, index) => (
                            <div
                                key={index}
                                className="unCurtain"
                                style={{
                                    animationDelay: `-${index * 0.1}s`
                                }}
                            ></div>
                        ))}
                    </div>
                    {/* RIGHT CURTAIN */}
                    <div
                        ref={rightCurtainRef}
                        className="curtainContainer right-0"
                        style={{
                            transform: "translateX(0%)",
                            zIndex: 99,
                        }}
                    >
                        {Array.from({ length: 12 }).map((_, index) => (
                            <div
                                key={index}
                                className="unCurtain"
                                style={{
                                    animationDelay: `-${index * 0.1}s`
                                }}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
            {/* SECOND */}
            <div
                ref={contentBelowRef}
                className="w-[100vw] h-[200vh]"
                style={{
                    zIndex: 90,
                }}
            >
            </div>

            {/* PATTERN */}
            <div
                ref={patternSectionRef}
                className="relative w-full h-[100vh] flex flex-col items-center justify-center"
                style={{
                    backgroundImage: "linear-gradient(to bottom, transparent, var(--secondary))",
                    zIndex: -1
                }}
            >
                {/* <img
                    src={pattern2}
                    alt="DoDeal"
                    className="animate-rotate w-[200px] h-auto object-contain w-[200px] h-auto object-contain absolute left-[5vw] bottom-[1vh]"
                /> */}
                <img
                    src={pattern1}
                    alt="DoDeal"
                    className="animate-rotate w-[200px] h-auto object-contain w-[200px] h-auto object-contain absolute right-[5vw] top-[1vh]"
                />
            </div>


            <div
                className="w-full h-[100vh] flex flex-col gap-5 items-center justify-center"
                style={{
                    background: "var(--secondary)",
                }}
            >
            </div>

            <div
                className="w-full h-[100vh] flex flex-col gap-5 items-center justify-center"
                style={{
                    backgroundImage: "linear-gradient(to bottom, var(--secondary), var(--primary))",
                }}
            >
            </div>

        </div>
    );
}

export default Home;
